import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import screenfull from 'screenfull'
// import { axios_file } from "@/assets/js/http_axios";
export default {
    inject: ["reload"],
    components: {
        draggable,
        paging,
        sorttable
    },
    data() {
        return {
            cangKu: '',
            huoQu: '',
            input: '',
            MembershipData: [{
                data: '1',
                name: '254424',
                address: '刘备'
            }],
            dialogImageUrl: '',
            dialogVisible: false,
            CancelSignIn: false,
            number2: "",
            viewPicture: false,
            form: {
                name: '',
            },
            WarehouseList: [], //仓库数组
            inspectionStatusList: [], //验货状态列表
            originalTabelHeadeTitle: [],
            originalTabelHeadeTitle1: [],
            rejectionStatusList: [], //拒收单拒收状态
            time1: [],
            time2: [],
            packageStatusList: [], ////包裹状态
            options: [{
                name: "a",
                val: 1,
            },
            {
                name: "b",
                val: 2,
            },
            ],
            // ======公用组件==========
            show_sortTableHeard1: false, //显示弹窗
            myTableHeard1: [], //我的表头
            selectLength1: 0, //显示的长度
            allCheck1: false, //全选
            tabelHeadeTitle1: [

                {
                    name: "仓库",
                    field: "storageName",
                    width: "120",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员名称",
                    field: "memberName",
                    width: "120",
                    sort: 3,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员号",
                    field: "memberId",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货架名称",
                    field: "frameName",
                    width: "120",
                    sort: 6,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货架编号",
                    field: "frameNumber",
                    width: "120",
                    sort: 6,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "上架状态",
                    field: "frameStatusShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "入库状态",
                    field: "stockStatusShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "快递公司",
                    field: "expressName",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "快递单号",
                    field: "courierNumber",
                    width: "160",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "签收人",
                    field: "signforUserName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "签收时间",
                    field: "signforTime",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹重量(KG)",
                    field: "packageWeight",
                    width: "120",
                    sort: 13,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹体积(m³)",
                    field: "packageVolume",
                    width: "120",
                    sort: 14,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "件数",
                    field: "boxCount",
                    width: "120",
                    sort: 12,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "长",
                    field: "length",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "宽",
                    field: "width",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "高",
                    field: "height",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "拒签状态",
                    field: "rejectionStatusShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "验货申请",
                    field: "checkStatusShow",
                    width: "120",
                    sort: 10,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货物名称",
                    field: "GoodsCategory",
                    width: "120",
                    sort: 11,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "入库备注",
                    field: "comment",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "入库时间",
                    field: "intoStockTime",
                    width: "180",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "操作人",
                    field: "intoUserName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },


            ],
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            fieldName: [], //下载字段名
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            sortName1: '模板名', //排序名
            pageGroupName: 'Stay', //页面标识
            FormData: { //签收
                count: '', //件数 必填
                courierNumber: '', //快递单号 必填
                sign: '', //	签名密匙 必填
                expressName: '', //快递公司
                memberId: '', //会员号
                storageId: '', //仓库ID
            },
            choice1: '', //勾选件数

            shangjiaarray: { //上架传值
                packageIds: '', //传值id 必传
                sign: '', //签名密匙 必传
                storageId: '', //仓库id
                storageCargoareaFrameNumber: '', //货架号
            },
            CargoAreaNumber: '', //货区ID
            inputt: '',

            //  --------------------  舒服的分割线  --------------------

            // ====== 公用组件 =========

            activeName: 'first', //  tab标识
            isActive: true, //  输入框绑定动态class
            judge: false, //   输入框绑定动态class
            MoreConditions: '展开更多', //  输入框绑定动态class

            //    待入库数据

            queryData: { //  搜索数据

            },
            queryData1: {},
            tableHeigth: "75vh", //  表格的高度
            tableHeigth1: "75vh", //  表格的高度
            tableData: [], //  表格数据
            tableData1: [], //  表格数据
            myTableHeard: [], //  表头参数
            tabelHeadeTitle: [{
                name: "快递单号",
                field: "courierNumber",
                width: "160",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递公司",
                field: "expressName",
                width: "160",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "仓库",
                field: "storageName",
                width: "120",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "memberName",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "120",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货架名称",
                field: "frameName",
                width: "120",
                sort: 6,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货架编号",
                field: "frameNumber",
                width: "120",
                sort: 6,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "上架状态",
                field: "frameStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "入库状态",
                field: "stockStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "签收人",
                field: "signforUserName",
                width: "120",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹签收时间",
                field: "signforTime",
                width: "160",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹重量(KG)",
                field: "packageWeight",
                width: "120",
                sort: 13,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "包裹体积(m³)",
                field: "packageVolume",
                width: "120",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "件数",
                field: "boxCount",
                width: "120",
                sort: 14,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "长",
                field: "length",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "宽",
                field: "width",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "高",
                field: "height",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "拒签状态",
                field: "rejectionStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "验货状态",
                field: "checkStatusShow",
                width: "120",
                sort: 10,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货物名称",
                field: "itemName",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹备注",
                field: "comment",
                width: "160",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "入库时间",
                field: "intoStockTime",
                width: "180",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "入库人",
                field: "intoUserName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            ], //  表头参数-默认
            pageNum: 1, //  分页配置--页码
            total: 0, // 分页配配置--总条数
            size: 50, //  分页配置--当前页总条数
            pageNum1: 1, //  分页配置--页码
            total1: 0, // 分页配配置--总条数
            size1: 50, //  分页配置--当前页总条数
            sizeList: [10, 20, 50, 100, 200], //  分页配置

            show_sortTableHeard: false, //  显示弹窗
            show_upload: false, //  显示上传弹窗
            selectLength: 0, //  显示的长度
            allCheck: false, //  全选
            totalArr: [], //  需要合计的字段
            sortName: '模板名', //  排序名
            //    上架确认
            editData: [], //  获取列表单条数据
            selectHuoJiaData: [], //  选择的货架
            dialogFormVisible: false, //  上架确认弹窗状态
            formSj: {
                storageCargoareaFrameNumber: ''
            }, //  上架确认表单
            cn: '', //  快递单号
            loading: false, //
            expressNumArr: [],
            singleData: {},
            cangkuList: [], //  仓库下拉数据
            kuaidiList: [], //快递下拉列表
            huoquList: [], //  获取下来数据
            huojiaList: [], //  货架下拉数据
            packageData: [], //  包裹数据

            visibleShowHJ: false,

            myTableHeardHj: [
                {
                    name: "货架编号",
                    field: "storageCargoareaFrameNumber",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                },
                {
                    name: "货架名称",
                    field: "storageCargoareaFrameName",
                    width: "120",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                },
                {
                    name: "货架票数",
                    field: "packageCount",
                    width: "100",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                },
                {
                    name: "所属货区",
                    field: "cargoareaName",
                    sort: 3,
                    width: "120",
                    isShow: true,
                    isTotal: false,
                },
                {
                    name: "所属仓库",
                    field: "storageName",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                },
                {
                    name: "创建时间",
                    field: "frameCreateTime",
                    width: "180",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                },
            ],

            isNullList: [
                {
                    name: '是',
                    val: 1
                },
                {
                    name: '否',
                    val: 0
                }
            ],
            isNull: 0,
        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
        if (this.show_sortTableHeard1) {
            // 排序表头
            let arr = this.tabelHeadeTitle1;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle1 = arr;
        }

    },
    created() {
        this.myTableHeard1 = this.tabelHeadeTitle;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }
        this.getGroupFindFieldSort(); //获取用户自定义表头
        this.myGetCkData()
    },
    beforeDestroy() { },
    mounted() {

        this.getData();
        const that = this;
        // window.onresize = () => {
        //     return (() => {
        //         that.fetTableHeight();
        //     })();
        // };
    },

    watch: {},
    methods: {
        //  ------------ 列表事件 ---------
        reload_s() { //  刷新页面
            this.reload();
        },

        getData() { //  获取首页数据
            this.queryData.pageStart = this.pageNum
            this.queryData.pageTotal = this.size
            Api.getPackageUpframeList(this.queryData).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData = res.data.result.data || [];

                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                }
            });
        },
        //包裹上加记录
        packageUpframeRecord() {
            this.queryData1.pageStart = this.pageNum1
            this.queryData1.pageTotal = this.size1
            Api.packageUpframeRecord(this.queryData1).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData1 = res.data.result.data || [];
                    this.total1 = res.data.result.pageCount || 0;
                    this.fetTableHeight1();
                }
            });
        },

        resetData() { //  搜索框数据重置
            this.queryData = {}
            this.time2 = []
        },
        resetData1() { //  搜索框数据重置
            this.queryData1 = {}
            this.time1 = []
        },


        handleSelectionChange(e) { //  表格选择事件
            this.editData = e
        },
        handleSelectionChangeHJ(e) { //  表格选择事件
            this.selectHuoJiaData = e
        },
        // 上架弹窗
        shangjiapost() {
            if (this.editData.length < 1) {
                this.$message.warning('请至少选择一个包裹');
                return
            }
            this.packageData = this.editData
            // this.myGetCkData()
            this.dialogFormVisible = true
        },

        //  -------- 待入库ediEvent ------------

        remoteMethod(query) { //  select框远程搜索
            if (query !== '') {
                this.loading = true;
                Api.getExpressNumData({
                    courierNumber: query
                }).then((res) => {
                    if (res.data.status === 'success') {
                        this.loading = false
                        this.expressNumArr = res.data.result
                        if (this.pageStatus === '3') {
                            this.newForm = res.data.result[0]
                            this.newForm.s = []
                        }
                    }
                });
            } else {
                this.expressNumArr = [];
            }
        },

        QE() { //   添加按钮
            if (this.cn == '') {
                this.$message.warning('请先选择快递单号再添加');
                return
            } else {
                this.packageData.push(this.singleData)
                this.singleData = {}
                this.cn = ''
            }

        },

        delbut(e) { //  移除
            this.packageData.splice(e, 1)
        },

        SE(val) { //  快递单号下拉框选中函数
            console.log(val)
            console.log('val')
            this.singleData = val
        },

        myGetCkData() { //  获取仓库快递下拉列表
            Api.getStorageList().then((res) => {
                if (res.data.status === 'success') {
                    this.cangkuList = res.data.result || []
                } else {
                    this.$message.error(res.data.message)
                }
            });
            Api.expressChooseList().then((res) => {
                if (res.data.status === 'success') {
                    this.kuaidiList = res.data.result || []
                    // console.log(res.data.result)
                }

            })
        },

        myGetHqData(id) { //  获取货区列表
            Api.getStorageCargoareaChooseList({
                storageId: id
            }).then((res) => {
                if (res.data.status === 'success') {
                    this.huoquList = res.data.result
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        myGetHjData() { //  获取货架列表
            // Api.getStorageCargoareaFrameChooseList({
            //     storageCargoareaId: val
            // }).then((res) => {
            //     if (res.data.status === 'success') {
            //         this.huojiaList = res.data.result
            //     } else {
            //         this.$message.error(res.data.message)
            //     }
            // })
            let formData = {
                pageStart: 1,
                pageTotal: 200,
                storageName: this.cangKu || '',
                cargoareaName: this.huoQu || '',
                isNull: this.isNull || 0,
                frameName: this.formSj.storageCargoareaFrameNumber || '',
            };

            Api.getStorageCargoareaFrameList(formData).then((res) => {
                if (res.data.status == "success") {
                    this.huojiaList = res.data.result.data || [];
                }
            });
        },

        cangkuClick(e) { //  仓库下拉框触发事件
            this.formSj.huoquId = ''
            this.formSj.storageCargoareaFrameNumber = ''
            this.cangKu = ''
            if (e) {
                // this.storageName
                this.cangkuList.forEach(item => {
                    if (item.id == e) {
                        this.cangKu = item.storageName
                    }
                })
            }
            this.myGetHqData(e) //  获取货区
        },

        huoquClick(e) { //  货区下拉框触发请求
            this.formSj.storageCargoareaFrameNumber = ''
            this.huoQu = ''
            if (e) {
                console.log('e', e)
                this.huoquList.forEach(item => {
                    if (item.id == e) {
                        let names = item.cargoareaName
                        this.huoQu = names
                        this.myGetHjData(names) //  获取货架
                    }
                })
            }
            // this.myGetHjData(e) //  获取货架
        },
        // 选择的货架
        changeBtns(row) {
            console.log(row)
            this.formSj.storageCargoareaFrameNumber = row.storageCargoareaFrameNumber
            this.visibleShowHJ = false

        },
        resetFn(formName) {
            //  * 注意使用这个表单重置的方法要在每个表单项加 prop属性，否则不生效
            this.$refs[formName].resetFields();
        },

        closeDt() { //  弹窗关闭回调函数
            this.resetFn('formSj')
        },

        packageFormSub() { //  入库确认提交
            if (this.packageData.length !== 0) {
                this.formSj.packageIds = []
                this.packageData.forEach(item => {
                    this.formSj.packageIds.push(item.packageId)
                })
                delete this.formSj.huoquId
                let parm = {
                    packageIds: this.formSj.packageIds,
                    storageCargoareaFrameNumber: this.formSj.storageCargoareaFrameNumber,
                    storageId: this.formSj.storageId,
                }
                let sign = tools.getSign(parm)
                parm.sign = sign
                Api.upFrame(parm).then((res) => {
                    if (res.data.status === 'success') {
                        this.$message.success(res.data.message)
                        this.dialogFormVisible = false
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            } else {
                this.$message.warning('至少上架一个包裹！！！')
                return
            }

        },

        // 获取状态展示值
        getStatusValList(status) {
            var dataArr = [];
            let param = {
                tableAndFieldName: status,
            };

            Api.getStatusValList(param).then((res) => {
                // 包裹状态
                if (status == "package_v1.package_status") {
                    let dataArr = res.data.result || [];
                    this.packageStatusList = dataArr
                }
                // 拒收单拒收状态
                if (status == "bill_rejection_v1.handle_status") {
                    let rejectionStatusList = res.data.result || [];
                    this.rejectionStatusList = rejectionStatusList;
                }
                // 验货单验货状态
                if (status == "bill_inspection_v1.check_status") {
                    let inspectionStatusList = res.data.result || [];
                    this.inspectionStatusList = inspectionStatusList
                }
            });

        },


        //取消上架
        CancelWarehousing() {
            if (this.editData.length < 1) {
                this.$message.error('请至少勾选一条数据');
            } else {
                let nidList = new Array
                let param = new Object
                this.editData.forEach((item) => {
                    nidList.push(item.courierNumber)
                })
                param.courierNumbers = nidList
                let sign = tools.getSign(param)
                param.sign = sign
                Api.cancelUpFrame(param).then((res) => {
                    if (res.data.status == "success") {
                        this.$message.success("取消上架成功")
                        this.packageUpframeRecord()
                    }
                })
            }
        },
        //退货
        returnGoods() {


            this.$router.push({
                path: '/BusinessManagement/Warehousing/RejectionTreatment',
                query: {
                    status: "newAdd"
                }
            })

        },
        //验货处理 
        InspectionHandling() {

            this.$router.push({
                path: '/BusinessManagement/Warehousing/InspectionHandling',
                query: {
                    pageType: 1,

                }
            })

        },

        //表格样式i
        tableRowClassName({
            row,
            rowIndex
        }) {
            if (rowIndex % 2 === 0) {
                return 'warning-row';
            }
        },

        handleCommandTabs(m) {
            if (m == "a") {
                this.myModel()
            } else if (m == 'b') {
                this.exportBtn()
            } else if (m == 'c') {
                this.exportTemplateDownload()
            } else if (m == 'd') {
                this.upLoadBtn()
            } else {
                this.screen()
            }

        },
        //全屏模式
        screen() {

            if (screenfull.isEnabled) {
                screenfull.toggle(this.$refs.tableWrapper);

            }

        },
        // 阻止F11默认事件
        keydown(event) {
            if (event.keyCode === 122) {
                event.preventDefault()
                event.returnValue = false
            }
        },
        //输入框绑定动态class
        more() {
            this.isActive = !this.isActive
            this.judge = !this.judge
            if (this.judge === false) {
                this.MoreConditions = '更多条件'
            } else {
                this.MoreConditions = '收起条件'
            }
        },
        rejection() {
            if (this.choice > 1) {
                this.$message.error('不能勾选两条或者以上的数据');
            } else if (this.choice < 1) {
                this.$message.error('请至少勾选一条数据');
            } else {
                this.$router.push("/BusinessManagement/Warehousing/RejectionTreatment");
            }


        },
        //付款确认
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //上传图片
        handleRemove(file, fileList) {
            //console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },


        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },

        // ===============================================导出部分=开始======
        // 上传导出模板
        uploadSectionFile(param) {
            //console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'Stay') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldNames.push(this.myTableHeard[i].field);
                            heads.push(this.myTableHeard[i].name);
                        }
                    }
                } else if (this.pageGroupName == 'AlreadyOn') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldNames.push(this.myTableHeard1[i].field);
                            heads.push(this.myTableHeard1[i].name);
                        }
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let className
            let that = this;
            let datas = []
            if (this.pageGroupName == 'Stay') {
                datas = that.tableData;
                className = that.className
            } else {

                datas = that.tableData1
                className = that.className
            }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            let array = {
                customizedExportId: customizedExportId,
                className: className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }
            Api.templateDataExport(array).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        timebut(e) {
            if (e && e.length > 0) {
                this.queryData1.intoStockStartTime = e[0] + " " + "00:00:00";
                this.queryData1.intoStockEndTime = e[1] + " " + "23:59:59";
            } else {
                this.queryData1.intoStockStartTime = ''
                this.queryData1.intoStockEndTime = ''
            }
            this.queryBtn_ok1();
        },
        timebut1(e) {
            if (e && e.length > 0) {
                this.queryData.intoStockStartTime = e[0] + " " + "00:00:00";
                this.queryData.intoStockEndTime = e[1] + " " + "23:59:59";
            } else {
                this.queryData.intoStockStartTime = '';
                this.queryData.intoStockEndTime = '';
            }
            this.queryBtn_ok();
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];
            if (this.pageGroupName == 'Stay') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            } else if (this.pageGroupName == 'AlreadyOn') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                console.log(res.data)
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'Stay') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "包裹待上架列表"
                        );
                    } else {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className,
                            this.tableData1,
                            "包裹已上架列表"
                        );
                    }

                }
            });
        },
        // ===============================================导出部分=结束======

        /**
         * ******************************计算表格高度**开始*************************
         */
        // 重置table高度
        resetHeight() {
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                this.tableHeigth1 = 0;
                resolve();
            });
        },
        // 设置table高度
        fetTableHeight() {
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },
        // 设置table高度
        fetTableHeight1() {
            this.resetHeight().then((res) => {
                this.tableHeigth1 = this.getHeight1();
                this.$nextTick(() => {
                    this.$refs.mytable1.doLayout();
                });
            });
        },
        // 获取表格高度
        getHeight() {
            let windonHeight = document.body.clientHeight
            let queryHeigth
            let btnListHeight = this.$refs.btnList1.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge == true) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === "second") {
                    queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                }

            } else {
                queryHeigth = 50
                if (this.activeName === "second") {
                    queryHeigth = 80
                }
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10;
            return Math.floor(windonHeight - otherHeight) || "auto"; //
        },
        // 获取表格高度
        getHeight1() {
            let windonHeight = document.body.clientHeight
            let queryHeigth = 0
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge == true) {
                queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                if (this.activeName === "second") {
                    queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                }

            } else {
                queryHeigth = 50
              
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10;
            return Math.floor(windonHeight - otherHeight) || "auto"; //
        },
        /**
         * ******************************计算表格高度**结束*************************
         */



        Warehousing(e) {
            console.log(e)
        },
        //上架确定
        shangjiabut() {
            let shangjiaarray = this.shangjiaarray

            //把货架ID转换为货架编号

            let encryption = {
                packageIds: shangjiaarray.packageId,
                storageCargoareaFrameNumber: shangjiaarray.storageCargoareaFrameNumber,
                storageId: shangjiaarray.storageId,
            };
            shangjiaarray.sign = tools.getSign(encryption);
            Api.upFrame(shangjiaarray).then((res) => {
                console.log(res)
            })

        },
        // 查询按钮
        queryBtn_ok() {
            this.pageNum = 1;
            this.getData()
        },
        // 已上架查询按钮
        queryBtn_ok1() {
            let nidlist = new Array
            nidlist = tools.getOrderNum(this.number2)
            this.queryData1.courierNumber = nidlist
            this.pageNum1 = 1;
            this.packageUpframeRecord()
        },
        // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
        getStr(orderNos) {
            let arr = [];
            let arr_1 = [];
            if (orderNos.indexOf("\n") !== -1) {
                arr = orderNos.split("\n");
                //  ['123 344,dsg ewe','234 233','32,234']
                for (var i = 0; i < arr.length; i++) {
                    let arr_a = [];
                    if (arr[i].indexOf(",") !== -1) {
                        arr_a = arr[i].split(",");
                        arr_a.map((item) => {
                            arr_1.push(item);
                        });
                    } else {
                        if (arr[i]) {
                            arr_1.push(arr[i]);
                        }
                    }
                }
                setTimeout(() => {
                    for (var j = 0; j < arr_1.length; j++) {
                        let arr_b = [];
                        if (arr_1[j].indexOf(" ") !== -1) {
                            arr_b = arr_1[j].split(" ");
                            arr_b.map((item) => {
                                arr_b.push(item);
                            });
                        } else {
                            if (arr_1[j]) {
                                arr_b.push(arr_1[j]);
                            }
                        }
                    }
                }, 20);
            } else if (orderNos.indexOf(",") !== -1) {
                arr = orderNos.split(",");
                //  ['123 344','234','32 234']
                for (var b = 0; b < arr.length; b++) {
                    let arr_a = [];
                    if (arr[b].indexOf(" ") !== -1) {
                        arr_a = arr[b].split(" ");
                        arr_a.map((item) => {
                            arr_1.push(item);
                        });
                    } else {
                        if (arr[b]) {
                            arr_1.push(arr[b]);
                        }
                    }
                }
            } else if (orderNos.indexOf(" ") !== -1) {
                arr = orderNos.split(" ");
                arr.map((item) => {
                    if (item) {
                        arr_1.push(item);
                    }
                });
            } else {
                arr_1 = [orderNos];
            }
            let endArr = [];
            for (var q = 0; q < arr_1.length; q++) {
                if (arr_1[q].indexOf(" ") != -1) {
                    let arrs = arr_1[q].split(" ");
                    for (var w = 0; w < arrs.length; w++) {
                        if (arrs[w]) {
                            endArr.push(arrs[w]);
                        }
                    }
                } else {
                    if (arr_1[q]) {
                        endArr.push(arr_1[q]);
                    }
                }
            }
            return endArr;
        },
        //顶部标签页切换
        handleClick() {
            //console.log(this.activeName)
            if (this.activeName == 'second') {
                this.pageGroupName = 'AlreadyOn'
                this.SignInRecord()
                this.pageNum1 = 1;
                this.packageUpframeRecord()


            } else {
                this.pageGroupName = 'Stay'
                this.pageNum = 1;
                this.getData()
                // this.getGroupFindFieldSort()
            }
            this.editData = []

        },
        //签收记录表头获取
        SignInRecord() {
            let that = this;
            //console.log(this.pageGroupName)
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        this.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    this.myTableHeard1 = this.tabelHeadeTitle1;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isTotal) {
                        totalArr.push(this.myTableHeard1[i].field);
                    }
                }
                this.totalArr1 = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard = that.tabelHeadeTitle;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard.length; i++) {
                    if (that.myTableHeard[i].isTotal) {
                        totalArr.push(that.myTableHeard[i].field);
                    }
                }
                that.totalArr = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },

        myModel() { // 设置模板按钮
            this.show_sortTableHeard = true;
            this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
                if (this.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength = selectLength;
            if (selectLength === this.tabelHeadeTitle.length) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }
        },

        myModel1() { //设置模板按钮
            this.show_sortTableHeard1 = true;
            this.tabelHeadeTitle1 = JSON.parse(JSON.stringify(this.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle1.length; i++) {
                if (this.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength1 = selectLength;
            if (selectLength === this.tabelHeadeTitle1.length) {
                this.allCheck1 = true;
            } else {
                this.allCheck1 = false;
            }
        },


        handleSelectionChange1(e) { //  表格选择事件
            this.editData = e
        },
        // 排序==>取消按钮(签收记录)
        cancelSortHeard() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard = e;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property == that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },
        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },
        //分页
        handleSizeChange1(val) {
            this.size1 = val;
            this.packageUpframeRecord();
        },
        handleCurrentChange1(val) {
            this.pageNum1 = val;
            this.packageUpframeRecord();
        },

    }
}